<template>
  <v-row
    id="destiny"
    class="wrapper overflow-hidden"
    align="center"
  >
    <v-col
      class="pl-8 pl-md-3"
      cols="12"
      md="5"
      lg="3"
      offset-md="1"
      offset-lg="2"
    >
      <v-card
        max-width="600"
        class="content mx-auto transparent"
        flat
      >
        <h1>Concept Submissions.</h1>
        <h2 class="font-italic">Your ideas. Your Vision.</h2>
        <p>Short Stories is a community oriented server. We allow our members to submit their own ideas for events, and if your concept is accepted, you are given temporary event master permissions to run it yourself, exactly to your own vision.</p>
        <p>The sky is the limit!</p>
        <v-btn
          href="https://willard.network/forums/wn/event-concept-submission.303/"
          class="mt-4"
          outlined
        >
          <v-icon
            class="mr-2"
            small
          >mdi-calendar</v-icon>Concept Submissions
        </v-btn>
      </v-card>
    </v-col>
    <div class="parallax"></div>
  </v-row>
</template>

<script>
export default {
}
</script>
<style lang="scss">
#destiny {
  .parallax {
    background-image: url("/public/assets/img/backgrounds/destiny.png");
    &:before {
      content: "";
      background-color: rgba(34, 32, 64, 0.7);
    }
  }
}
</style>
