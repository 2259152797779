<template>
  <v-row
    id="seas"
    class="wrapper overflow-hidden text-center"
    align="center"
    justify="center"
  >
    <v-col
      cols="12"
      md="6"
    >
      <v-card
        class="content transparent"
        flat
      >
        <h1>Settings</h1>
        <h2 class="mb-8">Event Settings</h2>
        <template>
          <v-timeline>
            <v-timeline-item
              v-for="(season, i) in season"
              :key="i"
              :color="season.color"
              small
            >
              <template v-slot:opposite>
                <span
                  :class="`headline font-weight-bold ${season.color}--text`"
                  v-text="season.month"
                ></span>
              </template>
              <div class="py-4">
                <h2 :class="`headline font-weight-light mb-4 ${season.color}--text`">
                  <b>{{season.title}}</b>
                </h2>
                <div>
                  {{season.desc}}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </template>
      </v-card>
    </v-col>
    <div class="parallax"></div>
  </v-row>
</template>
<script>
  export default {
    data: () => ({
      season: [
        {
          title: 'Until the Next, New Frontiers, Red Forest',
          desc: 'Events set in the world of Half Life, sometimes linked to the Main Server`s lore.',
          color: 'white',
          month: 'Half Life',
        },
        {
          title: 'Holy Light, 2150, Murder at the Occidental',
          desc: 'Events set within originally made worlds, specifically designed to be explored within the event.',
          color: 'white',
          month: 'Original Worlds',
        },
        {
          title: "Where Angels Fear to Tread, Vault 127, Submergence",
          desc: "Events based on Games, Movies, TV Shows, Books, or even real history.",
          color: 'white',
          month: 'History and Media',
        }
      ],
    }),
  }
</script>
<style lang="scss">
#seas {
  .parallax {
    background-image: url("/public/assets/img/backgrounds/seasons.png");
    &:before {
      content: "";
      background-color: rgba(34, 32, 64, 0.6);
    }
  }
}
</style>
