<template>
  <v-row
    id="team"
    class="wrapper overflow-hidden"
    align="center"
  >
    <v-col
      class="pl-8 pl-md-3"
      cols="12"
      md="5"
      lg="3"
      offset-md="1"
      offset-lg="2"
    >
      <v-card
        class="content transparent"
        flat
      >
        <h1>The Team.</h1>
        <h2 class="font-italic">Making it happen!</h2>
        <div class="d-block d-md-flex">
          <v-list
            color="rgba(0,0,0,0.4)"
            class="mr-md-12"
          >
            <template v-for="(item, index) in management">
              <v-subheader
                v-if="item.header"
                :key="item.header"
                { item.header }
              ></v-subheader>

              <v-divider
                v-else-if="item.divider"
                :key="index"
                :inset="item.inset"
              ></v-divider>

              <v-list-item
                v-else
                :key="item.name"
              >
                <v-list-item-avatar>
                  <v-img :src="item.avatar"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="d-flex align-center justify-space-between">{{item.name}}
                    <v-chip
                      class="ml-8"
                      outlined
                      x-small
                    >{{item.title}}</v-chip>
                  </v-list-item-title>

                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
          <v-list color="rgba(0,0,0,0.4)">
            <template v-for="(item, index) in devs">
              <v-subheader
                v-if="item.header"
                :key="item.header"
                { item.header }
              ></v-subheader>

              <v-divider
                v-else-if="item.divider"
                :key="index"
                :inset="item.inset"
              ></v-divider>

              <v-list-item
                v-else
                :key="item.name"
              >
                <v-list-item-avatar>
                  <v-img :src="item.avatar"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="d-flex align-center justify-space-between">{{item.name}}
                    <v-chip
                      class="ml-8"
                      outlined
                      x-small
                    >{{item.title}}</v-chip>
                  </v-list-item-title>

                </v-list-item-content>
                
              </v-list-item>
            </template>
          </v-list>
        </div>
        <a
          href="https://willard.network/forums/threads/become-part-of-the-team.1563/"
          target="_blank"
          class="font-italic text-decoration-none d-block mt-3"
          style="color: #FFCC00;"
        >Join the Team!</a>
      </v-card>
    </v-col>
    <div class="parallax"></div>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    management: [
      {
        avatar: 'assets/img/avatars/alessio.png',
        name: 'Alessio',
        title: 'Short-Stories Manager'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/hayden.png',
        name: 'Hayden',
        title: 'Co-Manager'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/craw.png',
        name: 'Craw',
        title: 'Co-Manager'
      }
    ],
    devs: [
      {
        avatar: 'assets/img/avatars/atle.png',
        name: 'Atle',
        title: 'Willard Community Director'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/radx.gif',
        name: 'Rad-X',
        title: 'Main Server Director'
      },
      { divider: true, inset: true },
      {
        avatar: 'assets/img/avatars/gb.png',
        name: 'gb',
        title: 'Lead SS Developer'
      }
    ]
  })
}
</script>
<style lang="scss">
#team {
  .parallax {
    background-image: url("/public/assets/img/backgrounds/team.png");
    &:before {
      content: "";
      background-color: rgba(34, 32, 64, 0.85);
    }
  }
}
</style>
