<template>
    <v-row
      id="gonedark"
      class="wrapper overflow-hidden text-center"
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          class="content transparent"
          flat
        >
          <h1>Event Supercut!</h1>
          <br />
          <h2 >Work In Progress - <a style="color:--v-primary-base;">Enjoy Something Silly Instead!</a></h2>
        <youtube :video-id="videoId" ref="youtube" @playing="playing"></youtube>
        <!--<button @click="playVideo">play</button>-->
        </v-card>
      </v-col>
      <div class="parallax"></div>
    </v-row>
  </template>
  <script>
  export default {
    data() {
      return {
        videoId: 'SIPO0in-Xqs'
      }
    },
    methods: {
      playVideo() {
        this.player.playVideo()
      },
      playing() {
        console.log('Yay we are watching!!!')
      }
    },
    computed: {
      player() {
        return this.$refs.youtube.player
      }
    }
  }
  </script>
  <style lang="scss">
  #gonedark {
    .parallax {
      background-image: url("/public/assets/img/backgrounds/gonedark.png");
      &:before {
        content: "";
        background-color: rgba(34, 32, 64, 0.6);
      }
    }
  }
  </style>
  
