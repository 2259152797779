<template>
  <v-row
    id="experience"
    class="wrapper overflow-hidden"
    align="center"
  >
    <v-col
      class="pl-8 pl-md-3"
      cols="12"
      md="5"
      lg="3"
      offset-md="1"
      offset-lg="2"
    >
      <v-card
        max-width="600"
        min-width="500"
        class="content mx-auto transparent"
        flat
      >
        <h1>What Are Short Stories?</h1>
        <p>Short Stories is an event-focussed server. We run one off events, often lasting a single day or weekend.</p>
        <p>Our team operates freely to create their own events, ran to their own vision. The freedom we give our staff team leads to a vast array of events with different rulesets, settings, and mechanics.</p>
        <v-btn
          href="https://discord.gg/Zu2ggkDzUA"
          target="_blank"
          class="mt-4 mr-3"
          outlined
        >
        <v-icon
          class="mr-2"
          small
        >mdi-microphone-message</v-icon>Discord
        </v-btn>
        <v-btn
          href="https://willard.network/forums/wn/short-stories-forum.115/"
          class="mt-4"
          outlined
        >
          <v-icon
            class="mr-2"
            small
          >mdi-forum</v-icon>Forums
        </v-btn>
        <a
          href="https://www.artstation.com/thereeno"
          target="_blank"
          class="font-italic text-decoration-none d-block mt-3"
          style="color: #FFCC00;"
        >Background artwork by Jason Chaudoin</a>
      </v-card>
    </v-col>
    <div class="parallax"></div>
  </v-row>
</template>
<script>

export default {

  data () {
    return {
      timerCount: 5
    }
  },

  watch: {
    timerCount: {
      handler () {
        setTimeout(() => {
          var random = Math.random() * (50 - 1) + 1
          this.timerCount = Math.floor(random)
        }, 2000)
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }

  }
}

</script>
<style lang="scss">
#experience {
  .parallax {
    background-image: url("/public/assets/img/backgrounds/experience.png");
    &:before {
      content: "";
      background-color: rgba(34, 32, 64, 0.7);
    }
  }
}
</style>
